<template>
  <div class="container mx-auto py-20">
    <!-- component -->
    <div class="mt-40 sm:mt-0">
      <div class="md:gap-6">
        <div class="md:col-span-1">
          <div class="mb-10 px-4 sm:px-0">
            <h3 class="text-3xl font-medium leading-6 text-gray-900">
              {{ t('QUALITIA DNS') }} : {{ t('Subscription') }}
            </h3>
            <p
              v-if="!isPosted"
              class="my-4 text-sm text-gray-600 w-1/2 mx-auto"
            ></p>
          </div>
        </div>
        <div
          class="w-1/2 text-left m-auto shadow overflow-hidden sm:rounded-md"
        >
          <div class="grid grid-cols-2 px-4 py-5 bg-white sm:p-6">
            <div class="pb-4 text-lg col-span-2">
              <div>
                <h2>{{ t('Payment failure') }}</h2>
              </div>
              <div>{{ t('Please try again.') }}</div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <a href="/ja/service/order/qt-dns">
                {{ t('Go to Application page') }}
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useTranslation } from '@/locales'

export default {
  name: 'ServiceOrderQTDNSCanceled',
  setup(props) {
    const { t } = useTranslation()
    return { t }
  },
  data() {
    return {
      plan: '',
      isPosted: false,
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
    }
  },
  methods: {},
}
</script>

<style scoped lang="postcss">
.grid div {
  @apply mx-2;
}

input[type='radio'] {
  @apply mt-1 py-2 px-3 block border border-gray-300 shadow-sm rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.required {
  @apply ml-2 bg-red-500 font-medium text-gray-50 text-center inline-block px-2 text-sm;
}
</style>
